import React from "react"
import gridElementStyles from './style.module.scss'

const Card = () => {

  return (
    <div className={gridElementStyles.card}></div>
  )
}

export default Card
