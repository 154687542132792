import React from "react"
import gridElementStyles from './style.module.scss'

const ListItem = () => {

  return (
    <div className={gridElementStyles.listItem}></div>
  )
}

export default ListItem
