import React from "react"
import anime from "animejs"
import Card from "../card"

class gridView extends React.Component {
  constructor () {
    super ();
    this.state = { module: null }
  }
  componentDidMount () {
    if (typeof window !== `undefined`) {
      const module = require("@mollycule/react-anime")
      this.setState({ module: module.default })
    }
  }

  render () {
    const { module: Anime } = this.state;
    if (!Anime) return null
    return (
    <div className='row'>
      <Anime
        in
        duration={ 1000 }
        appear
        onEntering={ {
          translateY: [-50, 0],
          opacity:    [0, 1],
          delay:      anime.stagger(200,)
        } }
        onExiting={ {
          translateY: [0, -50],
          opacity:    [1, 0],
          delay:      anime.stagger(200,)
        } }
        easing={ 'spring' }
      >
        <div className={ 'col-xs-12 col-sm-6 col-md-4 card' }><Card/></div>
        <div className={ 'col-xs-12 col-sm-6 col-md-4 card' }><Card/></div>
        <div className={ 'col-xs-12 col-sm-6 col-md-4 card' }><Card/></div>
        <div className={ 'col-xs-12 col-sm-6 col-md-4 card' }><Card/></div>
        <div className={ 'col-xs-12 col-sm-6 col-md-4 card' }><Card/></div>
        <div className={ 'col-xs-12 col-sm-6 col-md-4 card' }><Card/></div>
      </Anime>
    </div>
  )}
}
export default gridView
