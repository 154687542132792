import React from "react"
import anime from "animejs"
import ListItem from "../listItem"

class listView extends React.Component {
  constructor () {
    super ();
    this.state = { module: null }
  }
  componentDidMount () {
    if (typeof window !== `undefined`) {
      const module = require("@mollycule/react-anime")
      this.setState({ module: module.default })
    }
  }

  render () {
    const { module: Anime } = this.state;
    if (!Anime) return null
  return (
    <div>
      <Anime
        in
        duration={ 1000 }
        appear
        onEntering={ {
          translateY: [50, 0],
          opacity:    [0, 1],
          delay:      anime.stagger(200,)
        } }
        onExiting={ {
          translateY: [0, 50],
          opacity:    [1, 0],
          delay:      anime.stagger(200,)
        } }
        easing={ 'cubicBezier(.5, .05, .1, .3)' }
      >
        <div className={ 'col-xs-12 listItem' }><ListItem/></div>
        <div className={ 'col-xs-12 listItem' }><ListItem/></div>
        <div className={ 'col-xs-12 listItem' }><ListItem/></div>
        <div className={ 'col-xs-12 listItem' }><ListItem/></div>
        <div className={ 'col-xs-12 listItem' }><ListItem/></div>
        <div className={ 'col-xs-12 listItem' }><ListItem/></div>
      </Anime>
    </div>
  )}
}

export default listView
