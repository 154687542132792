import React from "react"
import Layout from "../components/layout"
import Grid from '../components/gridView'
import List from '../components/listView'
import SEO from "../components/seo"

import './style.scss'

const [GRID, LIST] = ['grid', 'list']

class IndexPage extends React.Component  {


  constructor () {
    super ();
    this.state = {
      view: GRID,
    }
  }

  renderView = () => {
    const { view } = this.state
    if (view == LIST) return <List active={view === LIST}/>
    else return <Grid active={view === GRID}/>
  }

  setView = (view) => {
    this.setState({ view })
  }

  render () {
    return (
      <Layout>
        <SEO title="asyavee.me" />
        <div className='row icons'>
          <div
            style={{ marginRight: '40px' }}
            onClick={() => this.setView(GRID)}
          ><span className="gg-menu-grid-r "></span></div>
          <div
            onClick={() => this.setView(LIST)}
          ><span className="gg-menu"></span></div>
        </div>
        {this.renderView()}
      </Layout>
    )}
}

export default IndexPage
